import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import watermark from "../images/sammons.png"
import { webScraper } from "../http"

const News = ({ data: { allContentfulNews } }) => {
  const [scrapeData, setScrapeData] = React.useState([])

  const newsData = allContentfulNews.edges[0].node

  React.useEffect(() => {
    webScraper.get("/api/blog-posts").then(({ data }) => setScrapeData(data))
  }, [])

  return (
    <Layout>
      <SEO title="News" />
      <div className="news page-container">
        <div className="container-summary">
          <h2>{newsData.title}</h2>
          <p>{newsData.newsHeader}</p>
        </div>
        <div className="news entry-container">
          <img src={watermark} alt="watermark" />
          {[...newsData.newsEntries.references, ...scrapeData]
            .sort((a, b) =>
              new Date(a.createDate) > new Date(b.createDate) ? -1 : 1
            )
            .map((node, i) => (
              <div key={i} className="news-entry">
                {node.companyLogo !== null ? (
                  <div className="news-entry-details">
                    <img
                      src={node.companyLogo.file.url}
                      alt={node.companyLogo.file.fileName}
                    />
                    <h4>{node.header}</h4>
                    <p>{node.createDate}</p>
                    {node.summary !== null ? (
                      <div className="news-entry-footer">
                        <p>{node.summary.summary}</p>
                        <a
                          className="learn-more-link"
                          href={node.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read More
                        </a>
                      </div>
                    ) : (
                      <div class="news-entry-footer">
                        <p></p>
                        <a
                          className="learn-more-link"
                          href={node.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read More
                        </a>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="news-entry-details">
                    <h4>{node.header}</h4>
                    <p>{node.createDate}</p>
                    {node.summary !== null ? (
                      <div class="news-entry-footer">
                        <p>{node.summary.summary}</p>
                        <a
                          className="learn-more-link"
                          href={node.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read More
                        </a>
                      </div>
                    ) : (
                      <div class="news-entry-footer">
                        <p></p>
                        <a
                          className="learn-more-link"
                          href={node.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Read More
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </Layout>
  )
}
export default News

export const pageQuery = graphql`
  {
    allContentfulNews {
      edges {
        node {
          slug
          title
          newsHeader
          newsEntries {
            references {
              header
              createDate
              author
              id
              link
              summary {
                summary
              }
              companyLogo {
                file {
                  url
                  fileName
                }
              }
            }
          }
        }
      }
    }
  }
`
